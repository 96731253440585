import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">
        <p parentName="li">{`Transcript Analysis: Performance analysis and costs improvements.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bug Fix: Channel as rule on Onsite Campaigns.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bug Fix: Fixed logout action url and redirect`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      